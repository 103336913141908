import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { Page, LayoutWrapperDashboardSideNav } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import css from './HistoryPage.module.css';
import { withViewport } from '../../util/uiHelpers';
import classNames from 'classnames';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import IconTooggleMenu from '../../components/IconTooggleMenu/IconTooggleMenu';
import IconCross from '../../components/IconCross/IconCross';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import { useConfiguration } from '../../context/configurationContext';
import {
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  resolveLatestProcessName,
  isBookingProcess,
} from '../../transactions/transaction';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  IconSpinner,
} from '../../components';
import { stateDataShape, getStateData } from '../InboxPage/InboxPage.stateData'
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { InboxItem } from '../InboxPage/InboxPage';

export const HistoryPageComponent = props => {
  const config = useConfiguration();
  const { currentUser, scrollingDisabled, intl, viewport, listings, fetchInProgress, transactions } = props;
  const isMobileLayout = viewport?.width && viewport?.width < 768;
  const title = intl.formatMessage({ id: 'HistoryPage.title' });
  const [show, setShow] = React.useState(false);
  const sidebarRef = useRef(null);
  const handleClickOutside = event => {
    if (event.target !== sidebarRef.current) {
      setShow(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  const toTxItem = tx => {
    const transactionRole = (tx?.customer?.id?.uuid == currentUser?.id?.uuid) ? TX_TRANSITION_ACTOR_CUSTOMER : TX_TRANSITION_ACTOR_PROVIDER;

    let stateData = null;
    try {
      stateData = getStateData({ transaction: tx, transactionRole, intl });

    } catch (error) {
      // If stateData is missing, omit the transaction from InboxItem list.
    }
    const pickType = lt => conf => conf.listingType === lt;
    const findListingTypeConfig = publicData => {
      const listingTypeConfigs = config.listing?.listingTypes;
      const { listingType } = publicData || {};
      const foundConfig = listingTypeConfigs?.find(pickType(listingType));
      return foundConfig;
    };
    const publicData = tx?.listing?.attributes?.publicData || {};
    const foundListingTypeConfig = findListingTypeConfig(publicData);
    const { transactionType, stockType } = foundListingTypeConfig || {};
    const process = tx?.attributes?.processName || transactionType?.transactionType;
    const transactionProcess = resolveLatestProcessName(process);

    const isBooking = isBookingProcess(transactionProcess);

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem
          transactionRole={transactionRole}
          tx={tx}
          intl={intl}
          stateData={stateData}
          stockType={stockType}
          isBooking={isBooking}
          currentUser={currentUser}
        />
      </li>
    ) : null;
  };

  return (
    <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
      <TopbarContainer />

      <div className={css.editListingPageWrapper}>
        <div
          ref={sidebarRef}
          className={classNames(css.sidebar, show && isMobileLayout ? css.sidebarMobile : null)}
        >
          <LayoutWrapperDashboardSideNav currentTab="HistoryPage" />
        </div>

        <div className={css.mainContent}>
          <div className={css.pageTitle}>
            <FormattedMessage id="HistoryPage.heading" />
            <span onClick={() => setShow(!show)}>
              {!show ? (
                <IconTooggleMenu className={css.menuIcon} />
              ) : (
                <IconCross rootClassName={css.closeIcon} />
              )}
            </span>
          </div>

          <br />
          <ul className={css.itemList}>
            {!fetchInProgress && currentUser?.id?.uuid ? (
              transactions.map(toTxItem)
            ) : (
              <li className={css.listItemsLoading}>
                <IconSpinner />
              </li>
            )}

          </ul>
        </div>
      </div>
      <FooterContainer />
    </Page>
  );
};
HistoryPageComponent.defaultProps = {
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

HistoryPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchOrdersOrSalesError, pagination, transactionRefs } = state.InboxPage;
  const { currentUser, currentUserNotificationCount: providerNotificationCount } = state.user;
  const {
    updateInProgress,
  } = state.ProfileSettingsPage;
  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
    updateInProgress
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const HistoryPage = compose(
  connect(mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl,
  withViewport
)(HistoryPageComponent);

export default HistoryPage;




