import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPassword.module.css';
const SIZE_SMALL = 'small';

const IconPassword = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);


  return (
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_273_1389)">
<path d="M22 22H20V20H22V22ZM16 22H18V20H16V22ZM26 22H24V20H26V22Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6 12V7C6 6.08075 6.18106 5.1705 6.53284 4.32122C6.88463 3.47194 7.40024 2.70026 8.05025 2.05025C8.70026 1.40024 9.47194 0.884626 10.3212 0.532843C11.1705 0.18106 12.0807 0 13 0C13.9193 0 14.8295 0.18106 15.6788 0.532843C16.5281 0.884626 17.2997 1.40024 17.9497 2.05025C18.5998 2.70026 19.1154 3.47194 19.4672 4.32122C19.8189 5.1705 20 6.08075 20 7V12H23C23.7956 12 24.5587 12.3161 25.1213 12.8787C25.6839 13.4413 26 14.2044 26 15V16.1C27.1303 16.3295 28.1465 16.9427 28.8764 17.8357C29.6063 18.7288 30.005 19.8466 30.005 21C30.005 22.1534 29.6063 23.2712 28.8764 24.1643C28.1465 25.0573 27.1303 25.6705 26 25.9V27C26 27.7956 25.6839 28.5587 25.1213 29.1213C24.5587 29.6839 23.7956 30 23 30H3C2.20435 30 1.44129 29.6839 0.87868 29.1213C0.31607 28.5587 0 27.7956 0 27L0 15C0 14.2044 0.31607 13.4413 0.87868 12.8787C1.44129 12.3161 2.20435 12 3 12H6ZM8 7C8 5.67392 8.52678 4.40215 9.46447 3.46447C10.4021 2.52678 11.6739 2 13 2C14.3261 2 15.5979 2.52678 16.5355 3.46447C17.4732 4.40215 18 5.67392 18 7V12H8V7ZM17 18C16.2044 18 15.4413 18.3161 14.8787 18.8787C14.3161 19.4413 14 20.2044 14 21C14 21.7956 14.3161 22.5587 14.8787 23.1213C15.4413 23.6839 16.2044 24 17 24H25C25.7956 24 26.5587 23.6839 27.1213 23.1213C27.6839 22.5587 28 21.7956 28 21C28 20.2044 27.6839 19.4413 27.1213 18.8787C26.5587 18.3161 25.7956 18 25 18H17Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_273_1389">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>

    
  );
};

const { string } = PropTypes;

IconPassword.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPassword.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPassword;
