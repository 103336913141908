import React from 'react'
import { CustomExtendedDataField, FieldCurrencyInput, FieldLocationAutocompleteInput, FieldRadioButton, FieldSelect, FieldTextInput, Form, PrimaryButton } from '../../components'
import { Form as FinalForm, Field } from 'react-final-form';
import css from './UpdateSignupDetailsPageFrom.module.css'
import { useConfiguration } from '../../context/configurationContext';
import { BUYER, offer, PERCENTAGE, SELLER } from '../../util/types';
import appSettings from '../../config/settings';
import { FormattedMessage } from 'react-intl';
import * as validators from '../../util/validators';
import { compensationPercentage } from '../../config/configListing';
import { autocompletePlaceSelected, autocompleteSearchRequired, composeValidators } from '../../util/validators';
import { getPropsForCustomUserFieldInputs } from '../../util/userHelpers';
import CustomExtendedDataFieldForEdit from '../../components/CustomExtendedDataField/CustomExtebdedDataFieldForEdit';
import arrayMutators from 'final-form-arrays';

const UpdateSignupDetailsPageFrom = (props) =>
(<FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    keepDirtyOnReinitialize={true}
    render={formRenderProps => {
        const {
            autoFocus,
            className,
            disabled,
            ready,
            formId,
            form: formApi,
            handleSubmit,
            updateInProgress,
            onListingTypeChange,
            intl,
            invalid,
            pristine,
            values,
            currentUser
        } = formRenderProps;
        const { userType } = currentUser?.attributes?.profile?.publicData || {};
        const identity = v => v;
        const config = useConfiguration();
        const marketplaceCurrency = config.currency;
        const { userFields } = config?.user || {};
        const userFieldProps = getPropsForCustomUserFieldInputs(userFields, intl, userType);
        const showCustomUserFields = userType && userFieldProps?.length > 0;
        // location
        const addressRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressRequired',
        });
        const addressNotRecognizedMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressNotRecognized',
        });
        return (
            <Form onSubmit={handleSubmit}>
                <div>
                    {showCustomUserFields ? (
                        <div className={css.customFields}>
                            {userFieldProps.map(fieldProps => (<div className={css.inputradioField}>
                                <CustomExtendedDataFieldForEdit
                                    {...fieldProps}
                                    formId={formId}
                                    userType={userType}
                                />
                                {fieldProps.key === "pub_compensationOffer" && (
                                    <>
                                        {values && values.pub_compensationOffer == 'percentage' && (
                                            <FieldSelect
                                                id={formId ? `${formId}.compPercentage` : 'compPercentage'}
                                                name="compPercentage"
                                                className={css.inputBox}
                                                label={intl.formatMessage({ id: 'SignupForm.compPercentageLabel' })}
                                            >
                                                <option disabled value="">
                                                    {intl.formatMessage({
                                                        id: 'SignupForm.compPercentagePlaceholder',
                                                    })}
                                                </option>
                                                {compensationPercentage.map(st => (
                                                    <option key={st.option} value={st.option}>
                                                        {st.label}
                                                    </option>
                                                ))}
                                            </FieldSelect>
                                        )}
                                        {values && values.pub_compensationOffer == 'fixed-amount' && (
                                            <FieldCurrencyInput
                                                id={formId ? `${formId}.compPrice` : 'compPrice'}
                                                name="compPrice"
                                                className={css.zipCode}
                                                label={intl.formatMessage({ id: 'SignupForm.compPriceLabel' })}
                                                placeholder={intl.formatMessage({
                                                    id: 'SignupForm.compPricePlaceholder',
                                                })}
                                                currencyConfig={appSettings.getCurrencyFormatting(marketplaceCurrency)}
                                            />
                                        )}
                                        <br />
                                    </>
                                )}
                            </div>))}
                        </div>
                    ) : null}
                    {userType == BUYER && (
                        <div>
                            <FieldCurrencyInput
                                id={formId ? `${formId}.minPrice` : 'minPrice'}
                                name="minPrice"
                                className={css.input}
                                autoFocus={autoFocus}
                                label={intl.formatMessage({ id: 'SignupForm.minPriceLabel' })}
                                placeholder={intl.formatMessage({
                                    id: 'SignupForm.minPriceePlaceholder',
                                })}
                                currencyConfig={appSettings.getCurrencyFormatting(marketplaceCurrency)}
                            />

                            <FieldCurrencyInput
                                id={formId ? `${formId}.maxPrice` : 'maxPrice'}
                                name="maxPrice"
                                className={css.zipCode}
                                autoFocus={autoFocus}
                                label={intl.formatMessage({ id: 'SignupForm.maxPriceLabel' })}
                                placeholder={intl.formatMessage({
                                    id: 'SignupForm.maxPricePlaceholder',
                                })}
                                currencyConfig={appSettings.getCurrencyFormatting(marketplaceCurrency)}
                            />
                        </div>
                    )}

                    <div className={css.locationInput}>
                        <label>
                            <FormattedMessage id="UpdateSignupForm.LocationLabel" />
                        </label>
                        <FieldLocationAutocompleteInput
                            rootClassName={css.locationAddressForm}
                            inputClassName={css.locationAutocompleteInput}
                            iconClassName={css.locationAutocompleteInputIcon}
                            predictionsClassName={css.predictionsRoot}
                            validClassName={css.validLocation}

                            name="location"
                            placeholder={intl.formatMessage({ id: 'SignupForm.addressPlaceholder' })}
                            useDefaultPredictions={false}
                            format={identity}
                            valueFromForm={values.location}
                            validate={composeValidators(
                                autocompleteSearchRequired(addressRequiredMessage),
                                autocompletePlaceSelected(addressNotRecognizedMessage)
                            )}
                        />
                    </div>
                    {userType == BUYER || userType == SELLER ? <div>
                        <FieldTextInput
                            className={css.zipCode}
                            type="number"
                            id={formId ? `${formId}.zipCode` : 'zipCode'}
                            name="zipCode"
                            autoComplete="given-name"
                            label={intl.formatMessage({ id: 'SignupForm.zipCodeLabel' })}
                            placeholder={intl.formatMessage({ id: 'SignupForm.zipCodePlaceholder' })}
                            validate={validators.required(
                                intl.formatMessage({ id: 'SignupForm.zipCodeRequired' })
                            )}
                        />
                    </div> : null}
                </div>
                <br />
                <PrimaryButton
                    className={css.updateButton}
                    type="submit"
                    inProgress={updateInProgress}
                    disabled={false}
                >
                    Update
                </PrimaryButton>
            </Form>
        )
    }}
/>

);


export default UpdateSignupDetailsPageFrom;
