import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import moment from 'moment';

const { UUID } = sdkTypes;
const sortedTransactions = txs =>
    reverse(
        sortBy(txs, tx => {
            return tx.attributes ? tx.attributes.lastTransitionedAt : null;
        })
    );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
    entities.map(entity => ({
        id: entity.id,
        type: entity.type,
    }));

const initialState = {
    fetchInProgress: false,
    fetchOrdersOrSalesError: null,
    pagination: null,
    transactionRefs: [],
};

export default function HistoryPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ORDERS_OR_SALES_REQUEST:
            return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
        case FETCH_ORDERS_OR_SALES_SUCCESS: {
            const transactions = sortedTransactions(payload.data.data);
            return {
                ...state,
                fetchInProgress: false,
                transactionRefs: entityRefs(transactions),
                pagination: payload.data.meta,
            };
        }
        case FETCH_ORDERS_OR_SALES_ERROR:
            console.error(payload); // eslint-disable-line
            return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

        default:
            return state;
    }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
    type: FETCH_ORDERS_OR_SALES_SUCCESS,
    payload: response,
});
const fetchOrdersOrSalesError = e => ({
    type: FETCH_ORDERS_OR_SALES_ERROR,
    error: true,
    payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
    // const { tab } = params;

    // const onlyFilterValues = {
    //   orders: 'order',
    //   sales: 'sale',
    // };

    // const onlyFilter = onlyFilterValues[tab];
    // if (!onlyFilter) {
    //   return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
    // }

    dispatch(fetchOrdersOrSalesRequest());

    const { page = 1 } = parse(search);

    const apiQueryParams = {
        // only: onlyFilter,
        lastTransitions: getAllTransitionsForEveryProcess(),
        include: [
            'listing',
            'provider',
            'provider.profileImage',
            'customer',
            'customer.profileImage',
            'booking',
        ],
        // 'fields.transaction': [
        //   'processName',
        //   'lastTransition',
        //   'lastTransitionedAt',
        //   'transitions',
        //   'payinTotal',
        //   'payoutTotal',
        //   'lineItems',
        // ],
        // 'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
        // 'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        page,
        perPage: INBOX_PAGE_SIZE,
    };
    // console.log(apiQueryParams.lastTransitions, "Check Data for rev trans")

    return sdk.transactions
        .query(apiQueryParams)
        .then(async response => {
            const data = response?.data?.data || [];
            console.log("Data", data)
            // Use Promise.all to handle multiple async operations
            // await Promise.all(data.map(async transaction => {
            //   const previoustxId = transaction?.attributes?.protectedData?.previoustxId;

            //   if (previoustxId) {
            //     const childTransaction = await sdk.transactions.show({ id: new UUID(previoustxId) });
            //     const childTransitions = childTransaction?.data?.data?.attributes?.transitions || [];

            //     transaction.attributes.transitions = [
            //       ...transaction.attributes.transitions,
            //       ...childTransitions,
            //     ].sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());
            //   }
            // }));

            // Dispatch actions after all promises are resolved
            dispatch(addMarketplaceEntities(response));
            dispatch(fetchOrdersOrSalesSuccess(response));

            return response;
        }).catch(e => {
            dispatch(fetchOrdersOrSalesError(storableError(e)));
            throw e;
        });
};
