import { parse } from '../../util/urlHelpers';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { denormalisedEntities, updatedEntities } from '../../util/data';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { updateAgentIdForShowOffer } from '../../util/api';

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/AgentOfferPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/AgentOfferPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/AgentOfferPage/FETCH_LISTINGS_ERROR';


// ================ Reducer ================ //


const initialState = {
  fetchListingInProgress: false,
  listings: null,
  fetchListingsError: null,
  pagination: null,
};

export default function OpenInboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {

    case FETCH_LISTINGS_REQUEST:
      return { ...state, fetchListingInProgress: true, fetchListingsError: null, listings: null };

    case FETCH_LISTINGS_SUCCESS:
      return { ...state, fetchListingInProgress: false, fetchListingsError: null, listings: payload.data, pagination: payload.pagination };

    case FETCH_LISTINGS_ERROR:
      return { ...state, fetchListingInProgress: false, fetchListingsError: true, listings: null };


    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchListingRequest = () => ({ type: FETCH_LISTINGS_REQUEST });

const fetchListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});

const fetchListingsError = response => ({
  type: FETCH_LISTINGS_ERROR,
  payload: response,
});


// ================ Thunks ================ //

const PAGE_SIZE = 100;

export const getProperResponseOfListing = (response, config) => {
  const responseEntries = response.data.data.length ? updatedEntities({}, response.data, { listingFields: config?.listing?.listingFields }) : {};
  const resources = response.data.data.length ? response.data.data.map((st) => ({ id: st.id, type: "listing" })) : [];
  const responseArray = response.data.data.length ? denormalisedEntities(responseEntries, resources, false) : [];
  return responseArray;
};


function toRadians(degrees) {
  return degrees * Math.PI / 180;
}

function toDegrees(radians) {
  return radians * 180 / Math.PI;
}

function getBoundingBox(lat, lng, distance) {
  const earthRadius = 6371; // Radius of the Earth in kilometers

  // Convert distance to radians
  const radDist = distance / earthRadius;

  // Convert latitude and longitude from degrees to radians
  const radLat = toRadians(lat);
  const radLng = toRadians(lng);

  // Calculate the bounding box coordinates
  const minLat = radLat - radDist;
  const maxLat = radLat + radDist;

  const minLng = radLng - radDist / Math.cos(radLat);
  const maxLng = radLng + radDist / Math.cos(radLat);

  // Convert back to degrees
  // NE lat, NE, lng, SW lat and SW lng.
  return `${toDegrees(maxLat)},${toDegrees(maxLng)},${toDegrees(minLat)},${toDegrees(minLng)}`;
  return {
    NE: {
      lat: toDegrees(maxLat),
      lng: toDegrees(maxLng)
    },
    SW: {
      lat: toDegrees(minLat),
      lng: toDegrees(minLng)
    }
  };
}


export const loadData = (params, search, config) => (dispatch, getState, sdk) => {

  const { page = 1 } = parse(search);


  const pageParams = {
    page,
    perPage: PAGE_SIZE,
  }

  return Promise.all([dispatch(fetchCurrentUser())])
    .then(async (response) => {
      dispatch(fetchListingRequest());
      const currentUser = response ? response[0] : getState().user.currentUser;
      const address1 = currentUser?.attributes?.profile?.publicData?.location || false;
      // distance in kilometers
      const distance = 450;
      const originMaybe = address1?.selectedPlace?.origin ? { bounds: getBoundingBox(address1.selectedPlace.origin.lat, address1.selectedPlace.origin.lng, distance) } : {};
      const listingParams = {
        ...pageParams,
        ...originMaybe,
        include: ['author'],
        pub_showOffer: "open",
      }
      const query = sdk.listings.query({ ...listingParams });
      return query.then((response) => {
        const listingFields = config?.listing?.listingFields;
        const sanitizeConfig = { listingFields };
        dispatch(addMarketplaceEntities(response, sanitizeConfig));
        const { data, meta } = response.data;
        if (address1) {
          const AgentId = currentUser.id.uuid;
          const listingIds = data.map(listing => listing?.id);
          const payload = {
            AgentId,
            listingIds
          }
          try {
            // Update agent ID in Listing
            updateAgentIdForShowOffer(payload)
          }
          catch (e) {
            console.error(e, '---- ---- => e');

          }
        }
        dispatch(fetchListingsSuccess({ data: data.map(st => st.id), pagination: meta }))
      })
        .catch(e => {
          dispatch(fetchListingsError(e));
        })

    })
};