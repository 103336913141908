/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React, { useEffect } from 'react';
import { node, number, string, shape, func } from 'prop-types';
import { compose } from 'redux';

import { FormattedMessage } from '../../util/reactIntl';
import { withViewport } from '../../util/uiHelpers';
import { Avatar, H3, H5, LayoutWrapperSideNav, ReviewRating } from '../../components';

import { createGlobalState } from './hookGlobalState';
import { connect, useDispatch, useSelector } from 'react-redux';
import css from './LayoutWrapperDashboardSideNav.module.css';
import { logout } from '../../ducks/auth.duck';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { AGENT, LISTING_STATE_PUBLISHED } from '../../util/types';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { queryUserReviews } from '../../containers/ProfilePage/ProfilePage.duck';
import IconProposal from '../IconProposal/IconProposal';
import IconProfile from '../IconProfile/IconProfile';
import IconPayment from '../IconPayment/IconPayment';
import IconPassword from '../IconPassword/IconPassword';
import IconOffer from '../IconOffer/IconOffer';
import IconNegociation from '../IconNegociation/IconNegociation';
import IconUpdate from '../IconUpdate/IconUpdate';
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

// Add global state for tab scrolling effect
const initialScrollState = { scrollLeft: 0 };

const { useGlobalState } = createGlobalState(initialScrollState);

// Horizontal scroll animation using element.scrollTo()
const scrollToTab = (currentTab, scrollLeft, setScrollLeft) => {
  const el = document.querySelector(`#${currentTab}Tab`);

  if (el) {
    // el.scrollIntoView doesn't work with Safari and it considers vertical positioning too.
    // This scroll behaviour affects horizontal scrolling only
    // and it expects that the immediate parent element is scrollable.
    const parent = el.parentElement;
    const parentRect = parent.getBoundingClientRect();
    const maxScrollDistance = parent.scrollWidth - parentRect.width;

    const hasParentScrolled = parent.scrollLeft > 0;
    const scrollPositionCurrent = hasParentScrolled ? parent.scrollLeft : scrollLeft;

    const tabRect = el.getBoundingClientRect();
    const diffLeftBetweenTabAndParent = tabRect.left - parentRect.left;
    const tabScrollPosition = parent.scrollLeft + diffLeftBetweenTabAndParent;
    const scrollPositionNew =
      tabScrollPosition > maxScrollDistance
        ? maxScrollDistance
        : parent.scrollLeft + diffLeftBetweenTabAndParent;

    const needsSmoothScroll = scrollPositionCurrent !== scrollPositionNew;
    if (!hasParentScrolled || (hasParentScrolled && needsSmoothScroll)) {
      // Ensure that smooth scroll animation uses old position as starting point after navigation.
      parent.scrollTo({ left: scrollPositionCurrent });
      // Scroll to new position
      parent.scrollTo({ left: scrollPositionNew, behavior: 'smooth' });
    }
    // Always keep track of new position (even if smooth scrolling is not applied)
    setScrollLeft(scrollPositionNew);
  }
};

const LayoutWrapperDashboardSideNavComponent = props => {
  const {
    onLogout,
    scrollingDisabled,
    user,
    userShowError,
    queryListingsError,
    queryReviewsError,
  } = props;

  const [scrollLeft, setScrollLeft] = useGlobalState('scrollLeft');
  const currentUser = ensureCurrentUser(useSelector(state => state?.user?.currentUser)) || {};
  const { firstName, lastName } = currentUser.attributes.profile || {};
  const fullName = firstName + " " + lastName || ""
  const userId = currentUser.id
  const reviews = useSelector(state => state?.ProfilePage?.reviews)
  const userType = currentUser?.attributes?.profile?.publicData?.userType;
  const isProvider = currentUser?.attributes?.profile?.publicData?.userRole == "provider";
  const currentUserHasListings = useSelector(state => state?.user?.currentUserHasListings);
  const currentUserListing = useSelector(state => state?.user?.currentUserListing);
  const totalRating = reviews && reviews?.reduce((sum, review) => sum + review.attributes.rating, 0);
  const averageRating = totalRating / reviews?.length;
  const id = (currentUserListing && currentUserListing?.id?.uuid) || '';

  useEffect(() => {
    const { currentTab, viewport } = props;
    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasFontsLoaded =
      hasViewport && document.documentElement.classList.contains('fontsLoaded');
    if (hasHorizontalTabLayout || hasFontsLoaded) {
      scrollToTab(currentTab, scrollLeft, setScrollLeft);
    }

    return () => {
      // Update scroll position when unmounting
      // const el = document.querySelector(`#${currentTab}Tab`);
      //  setScrollLeft(el.parentElement.scrollLeft);
    };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(queryUserReviews(userId));
  }, [dispatch, userId])
  const { currentTab } = props;

  const tabs = [
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.viewProfile" />,
      icon: <IconProfile />,
      selected: currentTab === 'ViewProfile',
      id: 'ViewProfile',
      linkProps: {
        name: 'ProfilePage',
        params: { id: currentUser?.id?.uuid },
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.historyPage" />,
      icon: <IconNegociation />,
      selected: currentTab === 'HistoryPage',
      id: 'HistoryPage',
      linkProps: {
        name: 'HistoryPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.payment" />,
      icon: <IconPayment />,
      selected: currentTab === 'PaymentMethodsPage',
      id: 'PaymentMethodsPage',
      linkProps: {
        name: 'PaymentMethodsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.UpdateDetails" />,
      icon: <IconUpdate />,
      selected: currentTab === 'UpdateSignupDetailsPage',
      id: 'UpdateSignupDetailsPage',
      linkProps: {
        name: 'UpdateSignupDetailsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.changePrivacy" />,
      icon: <IconPassword />,
      selected: currentTab === 'PrivacyChange',
      id: 'PasswordChangePageTab',
      linkProps: {
        name: 'PasswordChangePage',
      },
    },
  ];
  // if (userType == AGENT) {
  //   tabs.push(
  //     {
  //       text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.PurchaseCoins" />,
  //       icon: <IconUpdate />,
  //       selected: currentTab === 'PurchaseCreditsPage',
  //       id: 'PurchaseCreditsPage',
  //       linkProps: {
  //         name: 'PurchaseCreditsPage',
  //       },
  //     },
  //   )
  // }
  if (!isProvider) {
    tabs.splice(1, 0, {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.userDashboard" />,
      icon: <IconProposal />,
      selected: currentTab === 'InboxBasePage',
      id: 'InboxBasePage',
      linkProps: {
        name: 'InboxBasePage',
      },
    })
  }
  if (isProvider) {
    tabs.splice(1, 0, {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.agentOfferPage" />,
      icon: <IconOffer />,
      selected: currentTab === 'AgentOfferPage',
      id: 'AgentOfferPage',
      linkProps: {
        name: 'AgentOfferPage',
      },
    });
  }
  return (
    <>
      <div className={css.profileSection}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        <H3>{fullName}</H3>
        <H3>{currentUser?.attributes?.profile?.publicData?.name}</H3>
        <div className={css.reviewSec}>
          {
            reviews?.length > 0 ? <div className={css.reviewStars}>
              <ReviewRating
                rating={averageRating}
              />
            </div> : null
          }

          <p> {
            reviews?.length > 0 ? <span>
              {averageRating}  <span>  ({reviews?.length})</span>
            </span> : null
          }  </p>

        </div>

      </div>
      {currentUser?.id ? (
        <LayoutWrapperSideNav isDashboardSidetab={true} tabs={tabs} onLogout={onLogout} />
      ) : null}
    </>
  );
};

LayoutWrapperDashboardSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentTab: null,
};

LayoutWrapperDashboardSideNavComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentTab: string,
  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  onLogout: func.isRequired,
};

const mapStateToProps = state => {
  const {
    userId,
    userShowError,
    queryListingsError,
    queryReviewsError,
  } = state.ProfilePage;

  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    user,
    userShowError,
    queryListingsError,
    queryReviewsError,
  };
};
const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671

const LayoutWrapperDashboardSideNav = compose(
  withViewport,
  connect(mapStateToProps, mapDispatchToProps)
)(LayoutWrapperDashboardSideNavComponent);

export default LayoutWrapperDashboardSideNav;
